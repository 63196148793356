// Variables
@import 'variables';

.login {
  min-height: 100vh;
  .row {
    min-height: 100vh;
  }
  .authleftside {
    background: #f5f5f5;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .authrightside {
    max-width: 510px;
    /* padding-left: 110px; */
    position: absolute;
    left: 40%;
    right: 0;
    top: 50%;
    transform: translate(-40%, -50%);
  }
}

.maindashboard {
  min-height: 100%;
}
.mainsidebar {
  bottom: 0;
  float: none;
  left: 0;
  position: fixed;
  top: 0;
  // height: 100vh;
  overflow-y: hidden;
  z-index: 1038;
  background-color: $aliceblue;
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
  width: 270px;
  background-image: url('/assets/images/sidebarbg.svg');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  .dropdown {
    width: 100%;
    .dropdown-menu {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      transform: none !important;
      position: static !important;
      a:hover {
        font-weight: 400;
        background-color: transparent;
        color: $brightgray;
      }
    }
    .dropdown-item {
      border-radius: 4px;
      padding-left: 1rem;
      padding-right: 0.5rem;
      font-size: 16px;
      margin-bottom: 10px;
      &.active {
        background-color: $malibu;
        span:before {
          color: $white;
        }
      }
    }
    .dropdown-toggle {
      margin-bottom: 10px;
      .icon-Chevron-Down {
        transform: rotate(180deg);
      }
      &.show {
        background-color: $bluezodiac;
        *,
        span:before {
          color: $white;
        }
        .icon-Chevron-Down {
          transform: rotate(0deg);
        }
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .nav-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $brightgray;
      border-radius: 4px;
    }
    .icon {
      font-size: 22px;
    }
  }
}
.brand-link {
  padding: 20px 32px;
  display: block;
  img {
    max-width: 165px;
  }
}
.navlist {
  list-style: none;
  margin: 0;
  padding: 28px 32px;

  .active {
    background-color: $patternblue;
    color: $white;
    border-radius: 4px;
    span {
      color: $demin;
    }
    .icon:before {
      color: $demin;
      font-weight: 700;
    }
    a {
      font-weight: 600;
    }
    a.active {
      font-weight: 600;
      // background-color: $malibu;
    }
  }
  > div {
    > div.mr-2 {
      margin-bottom: 10px;
    }
  }
  .submenu a.active,
  .submenu.active a,
  .submenu.active {
    background-color: transparent;
  }
  .submenu a {
    padding-left: 52px;
    margin-bottom: 0;
  }
  li {
    // margin-bottom: 2px;
    // padding: 8px 16px;
    // color: $brightgray;
    // border-radius: 4px;
    // margin-bottom: 10px;
    .icon {
      display: inline-block;
      width: 35px;
      vertical-align: middle;
    }
    span,
    .icon:before {
      color: $brightgray;
    }
    .icon:before,
    .expand {
      font-size: 20px;
    }
    a {
      color: $black;
      text-decoration: none !important;
      display: block;
      margin-bottom: 2px;
      padding: 8px 16px;
      color: $brightgray;
      border-radius: 4px;
      margin-bottom: 0;
      &.active {
        font-weight: 600;
      }
    }
    // &:last-child{
    //   position: absolute;
    //   bottom: 0;
    // }
  }
}
.logout-link {
  position: absolute;
  bottom: 20px;
  right: 32px;
  left: 32px;
}

.maindashboard {
  padding: 24px 0;
}
.contentwrapper {
  transition: all 0.3s ease-in-out;
  margin-left: $menu-width;
  padding: 0 40px 68px;
}
.mainheader {
  transition: all 0.3s ease-in-out;
  margin-left: $menu-width;
  padding: 20px 40px 20px 40px;
}
.content {
  margin-top: 24px;
}

// auth: sign up
.careauth-sidebar-outer {
  background-color: $aliceblue;
  min-height: 100%;
  .careauth-sidebar-inner {
    max-width: 17.188rem;
    width: 100%;
    margin: 0 auto;
    padding: 5.625rem 0 0.063rem;
    .logo {
      img {
        max-width: 8.625rem;
        width: 100%;
      }
    }
    .auth-welcome-text p {
      color: $rockblue;
    }
  }
}

.login-bg {
  background-image: url('/assets/images/login-bg.png');
  min-height: 80vh;
  background-size: cover;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 0px 31px rgba(13, 115, 189, 0.45));
  border-radius: 13px;
}

.bg-before {
  &::before {
    content: url('/assets/images/icons/login-bg-icon.svg');
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}

.authcontent-outer {
  min-height: 100%;
  padding: 0rem 4.375rem 0rem 4.375rem;

  .logo {
    max-width: 12.625rem;
  }
  .authcontent-inner {
    max-width: 34.688rem;
    width: 100%;
    h3 + p,
    .normal > p {
      // margin-top: 1rem;
    }
    .account-type {
      background: $white;
      box-shadow: 0rem 0rem 1.5rem rgba(0, 19, 127, 0.05);
      border-radius: 0.313rem;
      padding: 1rem;
      padding-right: 2rem;
      .img-border {
        width: 4.25rem;
        height: 4.25rem;
        border-radius: 0.25rem;
        border: 0.063rem solid #d4eefa;
        background-color: $aliceblue;
        margin-right: 1.563rem;
      }
      .p2 {
        p {
          max-width: 19.438rem;
          width: 100%;
          line-height: 1.375rem;
        }
      }
      &:hover {
        .icon-RightArrow-Large {
          display: inline-block;
          transform: translateX(0.438rem);
        }
        background-color: $aliceblue;
      }
    }
    .p5 {
      span {
        span {
          margin-right: 0.313rem;
          font-size: 1.25rem;
          font-weight: normal;
          position: relative;
          top: 0.6.875rem;
        }
      }
    }
    .block-ul {
      @include custom-ul;
      li {
        margin-bottom: 1rem;
        span {
          &:nth-child(1) {
            font-size: 1.375rem;
            margin-right: 0.75rem;
          }
        }
      }
    }
    .code-inputs {
      div {
        max-width: 7.5rem;
        width: 100%;
        input {
          text-align: center;
        }
      }
    }
    .drag-or-browse {
      div {
        img {
          margin-right: 1.125rem;
        }
      }
      .p5 {
        padding-left: 1.25rem;
      }
      .p3 {
        p {
          &:nth-child(2) {
            font-size: $tertiary-fs;
            color: $rockblue;
            font-weight: normal;
          }
        }
      }
    }
    .file-upload {
      // background-color: $ghostwhite1;
      // border: 1px solid $ghostwhite1;
      border-radius: 0.25rem;
      padding: 0.75rem 1.125rem;
      .icon-Image {
        margin-right: 1rem;
        height: 1.438rem;
      }
      .p5 {
        span {
          display: inline-block;
          max-width: 11.563rem;
          width: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          top: 0.156rem;
        }
      }
    }
    .review-tab {
      .msgs {
        img {
          margin-right: 0.75rem;
          position: relative;
          top: 0.188rem;
        }
        a {
          color: $bluezodiac;
          font-weight: 700;
        }
      }
    }
  }
}
.authcontent-outer2 {
  padding: 6rem 6.25rem 1rem 6.875rem;
}
//header
.header-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.75rem;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding: 0 2.5rem;
  z-index: 99;
  .navbar-brand {
    img {
      width: 6.813rem;
    }
  }
  .navbar-brand2 {
    img {
      width: 146px;
    }
  }
  .navbar {
    width: 100%;
    .container-fluid {
      padding: 0;
    }
    .navbar-nav {
      margin-left: 7.5rem;
      .nav-item {
        margin-right: 2.688rem;
        &:last-child {
          margin-right: 0;
        }
        .nav-link {
          font-size: $secondary-fs;
          line-height: 1.375rem;
          color: $rockblue;
          display: flex;
          align-items: center;
          position: relative;
          .active-border {
            display: none;
            position: absolute;
            bottom: -1.25rem;
            left: 0;
            height: 0.188rem;
            background-color: $malibu;
            width: 100%;
          }
          .notif-count {
            position: absolute;
            min-width: 0.75rem;
            height: 0.75rem;
            border-radius: 6.25rem;
            background-color: $speechred;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 700;
            top: 0.313rem;
            left: 1.125rem;
            padding: 0.25rem 0.188rem 0.188rem;
          }
          span {
            &:nth-child(1) {
              margin-right: 1rem;
              font-size: 2rem;
            }
          }
          &.active,
          &:hover {
            color: $malibu;
            font-weight: 700;
            .active-border {
              display: block;
            }
            span:nth-child(1)::before {
              color: $malibu;
            }
            .icon-Dashboard-Unfilled::before {
              content: '\e91d';
            }
            .icon-Booking-Unfilled::before {
              content: '\e90d';
            }
            .icon-Chat-Unfilled::before {
              content: '\e914';
            }
            .icon-Payouts-Unfilled::before {
              content: '\e945';
            }
            .icon-Notification-Unfilled::before {
              content: '\e940';
            }
          }
          &:hover {
            .active-border {
              display: none;
            }
          }
        }
      }
    }
    .loggedin-user {
      div {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin-right: 0.75rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          border-radius: 50%;
        }
      }
      span {
        display: inline-block;
        color: $demin;
        max-width: 6.875rem;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.loggedin-user {
  .user-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      border-radius: 50%;
    }
  }
  span {
    display: inline-block;
    color: $demin;
    max-width: 11rem;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//provider profile view
.content-main {
  padding-top: 4.75rem;
  // background-color: $ghostwhite;
}
.provider-profile-content {
  max-width: 52.125rem;
  width: 100%;
  margin: auto;
}
.provider-basic-info {
  .profile-img {
    margin-right: 1.125rem;
    img {
      width: 4.5rem;
      height: 4.5rem;
      object-fit: cover;
      overflow: hidden;
      border-radius: 0.313rem;
    }
  }
  .profile-data {
    max-width: 90%;
    width: 100%;
  }
  .profile-data-ul {
    li {
      border-right: 0.063rem solid $aliceblue;
      padding: 0 0.875rem;
      vertical-align: middle;
      &:last-child {
        border: none;
        padding-right: 0;
      }
      .reviews-li {
        .icon-Star-fill {
          margin-left: -0.438rem;
        }
        .p5 span {
          color: $demin;
        }
        .p4 span {
          color: $bluezodiac;
        }
      }
      &:nth-child(1) {
        padding-left: 0;
        span {
          &:nth-child(1) {
            margin-right: 0.438rem;
          }
          &:nth-child(2) {
            color: $bluezodiac;
          }
        }
      }
    }
  }
}
.provider-detail {
  .tick-head {
    h4 {
      img,
      .badge {
        margin-left: 0.313rem;
      }
      .badge {
        font-style: italic;
        padding: 0.25rem 0.313rem;
        font-weight: 700;
        position: relative;
        top: -0.188rem;
      }
    }
  }
  .certif-icon {
    margin-right: 1rem;
  }
  .certif-name {
    position: relative;
    top: 0.125rem;
    padding-right: 1.25rem;
    p {
      font-size: $tertiary-fs;
      color: $rockblue;
      span {
        font-weight: 700;
      }
    }
  }
  .certif-approved {
    .certif-icon {
      margin-right: 1.125rem;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.313rem;
      border: 0.094rem solid $malibu;
      // background-color: $ghostwhite1;
    }
    .certif-name {
      padding-right: 0;
    }
  }
  .care-dropmenu {
    height: 1.5rem;
    .dropdown-menu {
      min-width: 8.813rem;
    }
  }
  .rates-div {
    .rates-border {
      border-bottom: 0.063rem solid $aliceblue;
      padding: 1.25rem 0;
      &:first-child {
        padding: 0 0 1.25rem;
      }
      &:last-child {
        border-bottom: none;
        padding: 1.25rem 0 0;
      }
    }
  }
  .review-img {
    margin-right: 0.875rem;
    height: 2.25rem;
    img {
      max-width: 2.25rem;
      height: 2.25rem;
      object-fit: cover;
      overflow: hidden;
      border-right: 0.313rem;
    }
  }
  .review-user {
    p,
    span {
      line-height: 1.125rem;
    }
    span {
      font-size: $tertiary-fs;
      color: $rockblue;
    }
  }
  .rating-div {
    .p5 span {
      color: $bluezodiac;
    }
  }
}
.badges-ul {
  li {
    margin-right: 0.938rem;
    margin-bottom: 0.313rem;
  }
}

//footer
footer {
  background-color: $white;
  border-top: 0.063rem solid #f1f7fa;
  padding: 24px 40px;
  position: fixed;
  left: 270px;
  right: 0;
  bottom: 0;
  z-index: 9;
  p {
    font-size: 12px;
    color: $rockblue;
  }
}

//pdf view
.pdf-backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(38, 38, 38, 0.8);
  .header-pdf {
    background-color: $bluezodiac;
    height: 3.875rem;
    box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    padding: 1rem 2.875 1rem 4.375rem;
    .icn-plus-name {
      padding-right: 1.25rem;
      .icon-file {
        margin-right: 1rem;
      }
      .file-name {
        p {
          color: $white;
        }
      }
    }
    .link-div {
      a {
        color: $white;
        &:hover {
          span::before {
            color: $white;
          }
        }
      }
    }
  }
  .pdf-main {
    padding: 8rem 1.25rem 3.75rem;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    z-index: 10;
    .content-pdf {
      img {
        max-width: 50.813;
        width: auto;
      }
      &.card-img {
        img {
          filter: drop-shadow(0rem 0rem 5.7rem rgba(38, 38, 38, 0.2));
          border-radius: 1.5rem;
          max-width: 58.625rem;
          width: auto;
        }
      }
    }
    .widget-file {
      background-color: $bluezodiac;
      border-radius: 0.313rem;
      padding: 0.813rem 2.063rem;
      max-width: 11.25rem;
      width: 100%;
      margin: 2.5rem auto;
      display: flex;
      .icon-ZoomOut {
        padding: 0 1.25rem;
      }
      .icon-deactive {
        span {
          opacity: 0.5;
          &:hover {
            color: $white;
          }
        }
      }
      span {
        color: $white;
        cursor: pointer;
        &:hover {
          color: $malibu;
        }
      }
    }
  }
}

//modal delete
.delete-p {
  span {
    font-weight: 700;
  }
}

//dashboard main
.dashboard-main {
  padding: 0 2.5rem;
  .dash-head {
    p {
      span {
        font-weight: 700;
      }
    }
  }
  .wave-bgs {
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: 0.313rem;
    background-size: cover;
    &.bg-brightgray {
      // background-image: url('/assets/images/quartz-wave.svg');
    }
    // &.bg-snuff {
    //   background-image: url('/assets/images/snuff-wave.svg');
    // }
    // &.bg-cosmos {
    //   background-image: url('/assets/images/cosmos-wave.svg');
    // }
    .dash-box {
      padding: 1.75rem 1.875rem;
      .icn-with-text {
        padding-right: 1.25rem;
        .icnn {
          margin-right: 0.938rem;
        }
        .texxt {
          font-size: $tertiary-fs;
          color: $demin;
          line-height: 1.021rem;
        }
      }
    }
  }
  .caret-drop {
    a {
      color: $malibu;
      span {
        padding-left: 1rem;
        &::before {
          color: $malibu;
        }
      }
    }
  }
  .client-list {
    border-bottom: 0.063rem solid $aliceblue;
    padding: 1rem 0;
    &:last-child {
      border: none;
      padding: 1rem 0 0;
    }
  }
  .iccn-client {
    img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.313rem;
      object-fit: cover;
      overflow: hidden;
      margin-right: 1rem;
    }
  }
  .client-name {
    p {
      line-height: 1.5rem;
    }
  }
  .icons-client {
    span {
      &:nth-child(2) {
        height: 1.5rem;
      }
    }
  }
}

//client view
.clients-img {
  margin-right: 1rem;
  img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    overflow: hidden;
    border-radius: 0.313rem;
  }
}

//booking hi-fi
.booking-content {
  max-width: 42.875rem;
  width: 100%;
  margin: auto;
}
.caret-drop-demin {
  a.dropdown-toggle {
    color: $demin !important;
    font-size: $tertiary-fs;
    font-weight: 700;
  }
}
.text-700 {
  span {
    font-weight: 700;
  }
}
.three-spans {
  span {
    &:nth-child(3) {
      font-size: 1.188rem;
      cursor: pointer;
    }
  }
}
.tooltip-bg {
  $tooltip-bg: rgba(0, 0, 0, 0.75) !important;
}
.p-demin {
  span {
    color: $demin;
    font-weight: 700;
  }
}
.timeline-ul {
  @include custom-ul;
  li {
    position: relative;
    margin-bottom: 1.688rem;
    .clock-line {
      margin-right: 0.75rem;
    }
    .timeline-content {
      font-size: $secondary-fs;
      span {
        color: $rockblue;
        font-size: $tertiary-fs;
        &:first-child {
          padding-right: 0.875rem;
        }
        &:last-child {
          a {
            img {
              margin-right: 0.438rem;
            }
            &:hover {
              color: $malibu;
            }
          }
        }
      }
    }
    .linee {
      position: absolute;
      width: 0.063rem;
      height: 70%;
      background-color: $brightgray;
      top: 2.031rem;
      left: 0.75rem;
    }
  }
}

//popups
.white-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1rem;
}
//breakdown popup
.table > :not(:last-child) > :last-child > * {
  // border-bottom-color: $ghostwhite1;
}
.bordered-table {
  border-collapse: separate;
  border-spacing: 0;
  tbody {
    td:first-child {
      border-right: 0.063rem solid $aliceblue;
      border-left: 0.063rem solid $aliceblue;
    }
    td:last-child {
      border-right: 0.063rem solid $aliceblue;
    }
    tr:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 0.25rem;
        }
        &:last-child {
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }
  td {
    color: $bluezodiac;
    span {
      font-weight: 400;
    }
  }
  th,
  td {
    padding: 0.438rem 1rem;
    font-size: $tertiary-fs;
    font-weight: 700;
  }
  th {
    color: $demin;
    // background-color: $ghostwhite1;
    // border: 0.063rem solid $ghostwhite1;
    &:first-child {
      border-top-left-radius: 0.25rem;
    }
    &:last-child {
      border-top-right-radius: 0.25rem;
    }
  }
}

//checkout popup
.digit-code {
  text-align: center;
  h1 {
    color: $malibu;
    letter-spacing: 1.875rem;
    padding-left: 1.875rem;
  }
}
.p-with-a {
  p {
    a {
      font-size: $primary-fs;
      text-decoration: underline;
      color: $bluezodiac;
      &:hover {
        color: $malibu;
      }
    }
  }
}
.h-with-icon {
  h4 {
    color: $demin;
  }
  span::before {
    color: $malibu;
  }
}
.checkout-radio.custom_radio {
  input[type='radio'] + label {
    font-weight: 600;
    font-size: $primary-fs;
  }
}
.action-bar {
  .btn {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    &:hover {
      background-color: $aliceblue;
    }
    &:focus {
      background-color: $aliceblue;
    }
  }
}
.tabs-wrapper {
  box-shadow: 0px 0px 24px rgba(0, 19, 127, 0.05);
  padding: 1rem;
}
.view-provider-table {
  .table > :not(caption) > * > * {
    padding: 16px 12px 12px;
  }
}
.no-data-found h6 {
  text-align: center;
  font-weight: bold;
  padding: 1rem 0;
}
.modal-terms-condition {
  .modal-content {
    border-radius: 4px;
    .modal-body {
      padding: 32px;
      padding-right: 16px;
      .scroll-text {
        max-height: 448px;
        overflow: auto;
      }
      .terms-and-privacy-policy {
        font-size: 14px;
        p,
        h4 {
          margin-bottom: 1em;
        }
        h4 {
          font-size: 14px;
        }
        .terms-highlight {
          color: $termsBlue;
        }
        .cooky-policy {
          color: $darkBlue;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $aliceblue;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $demin;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $demin;
  }
}

.modal-choosepayrate {
  .modal-content {
    border-radius: 4px;
    .modal-body {
      padding: 32px;
      .choose-importtype {
        > a,
        .manualCard {
          display: block;
          color: inherit;
          text-decoration: none;
          border: 1px solid $rockblue;
          border-radius: 4px;
          padding: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 24px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            border: 1px solid $demin;
            background: #d4eefa;
            .icon {
              color: $demin;
            }
          }
          h4 {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

.modal-list-assignments,
.malpracticestatemodal,
.payperiodProvidersModal,
.notificationsProvidersModal {
  .modal-dialog {
    max-width: 910px;
  }
  .modal-content {
    border-radius: 4px;
    .modal-body {
      padding: 32px;
    }
  }
}
.modal-choosepayperiod,
.modal-downloadpayment {
  .modal-content {
    border-radius: 4px;
    .modal-body {
      padding: 32px;
    }
  }
}
.malpracticestatemodal {
  thead {
    tr {
      th {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  tr {
    td {
      &:last-child {
        padding-right: 32px !important;
        text-align: right;

        .table-dropdown {
          .btn {
            padding: 0;
          }
          .dropdown-menu {
            text-align: left;
          }
        }
      }
    }
  }
}
.modal-editpractice {
  &.show {
    background: rgba(38, 38, 38, 0.8);
  }
  .modal-content {
    .modal-body {
      padding: 32px;
      .border-none {
        border: none;
      }
    }
  }
}

.terms {
  margin-left: 4px;
  font-weight: 700;
  color: $black;
  cursor: pointer;
  text-decoration: underline;
}
.providerlistingtable {
  tr {
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    td:nth-child(8),
    td:nth-child(9),
    td:nth-child(10),
    td:nth-child(11),
    td:nth-child(12) {
      min-width: 145px;
      padding-right: 16px;
    }
    td:last-child {
      // position: relative;
    }
  }
}
.payrateslistingtable {
  tr {
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      min-width: 145px;
      padding-right: 16px;
    }
  }
}
// Arrow click icon
.malpracticelistingtable {
  tr {
    td:first-child {
      position: relative;
      &::before {
        font-family: 'icomoon';
        content: '\e90c';
        margin-right: 5px;
        font-size: 16px;
        color: $demin;
        vertical-align: middle;
      }
    }
  }
}

.notificationsListingTable {
  tr {
    height: 46px;
  }
}

.payperiodProvidersModal,
.notificationsProvidersModal {
  &.modal .modal-content .modal-body {
    table tr {
      th,
      td {
        padding-left: 1.645rem;
      }
    }
  }

  .notificationsProviderModalTable {
    margin-top: 6px !important;
  }
}

.superadmin-provider-wrapper,
.update-password-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 19, 127, 0.05);
  border-radius: 4px;
  padding: 16px;
}

.superadmin-provider-wrapper {
  h4,
  p {
    color: $brightgray;
  }
}

.provider-filter,
.assign-facility-filter {
  .scroll {
    max-height: 300px;
    overflow-y: auto;
  }
}
.viewscreen {
  h4,
  p {
    color: $brightgray;
    word-wrap: break-word;
    line-height: 25px;
  }
  line-height: 25px;
}

.logout-link {
  .cursor-pointer {
    background-color: transparent;
  }
}
.addmore {
  background-color: transparent;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  color: $demin;
  width: fit-content;

  span {
    font-weight: bold;
    color: $demin;
    margin-right: 6px;
  }
}
.light-weight {
  &::before {
    font-weight: 400 !important;
  }
}
.view-doc-thumb {
  border: 1px solid $rockblue;
  border-radius: 4px;
  padding: 18px 24px;
}
.unauthorized {
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-top: -1rem;
  }
  img {
    max-width: 100%;
  }
}
.input-bg-disabled {
  background-color: #f1f1f1;
}

.pageNotFound {
  min-height: 100vh;
  .cta404 {
    margin-top: -10rem;
  }
}

// add commas after providers name in timesheet remainders
.providers-separator {
  &::after {
    content: ' , ';
    color: #94a3b8;
  }

  // remove commas from the last name
  &:last-child::after {
    content: none;
  }
}

// Code verification input
.otpwrapper {
  margin: 16px 0 28px 0;
  .vi_wrapper {
    width: 100%;
  }
  .vi__container {
    gap: 8px;
    width: auto;
    justify-content: space-between;
  }
  .vi__character {
    gap: 35px;
  }
  .vi__character {
    font-size: 16px;
    color: $black;
    background: $white;
    border: 1px solid $rockblue;
    flex-grow: inherit;
    flex-basis: auto;
    border-radius: 4px;
    width: 48px !important;
    height: 48px !important;
    &:last-child {
      margin-right: 0;
    }
  }
  :where(.vi__wrapper) {
    width: auto;
  }
}

// Code verification modal
.modal-codeverification {
  backdrop-filter: blur(6px);
  .modal-content {
    border-radius: 4px;
    .modal-body {
      padding: 32px;
    }
  }
}

// Not found common component
.no-data-found-wrap {
  padding: 5rem 0rem;
  width: 100%;
  .icon {
    &::before {
      color: $demin;
    }
  }
  h4 {
    color: $demin;
  }
  .not-found-icon {
    font-size: 60px;
  }
}

// timesheet weekly total
.timesheet {
  .regularshift-col {
    .main {
      display: flex;
      justify-content: center;
    }
  }
  .regularhour-col,
  .additionalhour-col,
  .oncalhour-col {
    text-align: center;
  }
}

// react-date picker
.react-datepicker-wrapper {
  width: 100%;
}

// alert bar scroll
.alert-scroll {
  max-height: 200px;
  overflow-y: auto;
}
