.clr-malibu {
  &[class^='icon-'] {
    &:before {
      color: $malibu;
    }
  }
}

.p-14 {
  padding: 0.875rem;
}

.pt-64 {
  padding-top: 4rem;
}

.pt-4 {
  padding-top: 0.25rem !important;
}

.pt-8 {
  padding-top: 0.313rem;
}

.pt-11 {
  padding-top: 0.688;
}

.pt-45 {
  padding-top: 2.813rem;
}

.pb-11 {
  padding-bottom: 0.688rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-28 {
  padding-left: 1.75rem;
}

.pl-10 {
  padding-left: 1rem;
}

.pr-5 {
  padding-right: 0.313rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-16 {
  padding-right: 1rem;
}

.pr-28 {
  padding-right: 1.75rem;
}

.pr-12 {
  padding-right: 0.75rem;
}

.mr-16 {
  margin-right: 1rem;
}

.ml-24 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 0.313rem;
}

.mt-12 {
  margin-top: 0.75rem;
}

.mt-10 {
  margin-top: 1rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-16 {
  padding-left: 1rem;
}

.fs-40 {
  font-size: 2.5rem;
}

.fs-34 {
  font-size: 2.125rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-10 {
  font-size: 1rem;
}

.fw-400 {
  font-weight: 400;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

.mr-6 {
  margin-right: 0.375rem;
}

.mb_1 {
  margin-bottom: 0.063rem;
}

.mb_2 {
  margin-bottom: 0.125rem;
}

.mb-8 {
  margin-bottom: 0.313rem;
}

.mb-15 {
  margin-bottom: 0.938rem;
}

.mb-12 {
  margin-bottom: 0.75rem;
}

.br-8 {
  border-radius: 0.313rem;
}

.br-4 {
  border-radius: 0.25rem;
}

.lh-20 {
  line-height: 1.25rem;
}

.lh-36 {
  line-height: 2.25rem;
}

.bg-ghostwhite-1 {
  // background-color: $ghostwhite1;
}

.outline-none {
  outline: none;
}

.minw-170 {
  min-width: 170px !important;
}

.minw-140 {
  min-width: 140px !important;
}

.btn {
  &.btn-rockblue {
    color: $rockblue;
    background-color: transparent;

    &:hover {
      background-color: $white;
      box-shadow: 0.313rem 0.313rem 1rem rgba(0, 19, 127, 0.25);
    }
  }
}

.edit-profile-pic {
  position: relative;
  max-width: 9.063rem;
  display: inline-block;

  .avatar-img {
    width: 128px;
    height: 128px;
    object-fit: cover;
    border-radius: 100px;
    border: 2px solid $malibu;
  }

  a {
    position: absolute;
    bottom: 0.188rem;
    right: 0.188rem;
    background-color: $bluezodiac;
    display: flex;
    height: 2rem;
    width: 2rem;
    justify-content: center;
    border: 0.094rem solid $white;
    border-radius: 1.875rem;
    text-decoration: none;

    span {
      &.icon-Edit-Small {
        cursor: pointer;
      }

      &::before {
        color: white;
      }
    }
  }
}

.form-footer-full {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  z-index: 1;
}

.form-data-info {
  p {
    font-weight: 700;
    line-height: 1.25rem;
  }
}

.authcontent-outer {
  .provider-profile-data {
    &.authcontent-inner {
      .file-upload {
        padding-top: 0.313rem;
        padding-bottom: 0.313rem;
      }
    }
  }
}

.entered-options {
  list-style-type: none;

  li {
    float: left;
    background-color: #f3f3ff;
    border-radius: 0.25;
    margin-right: 0.813rem;
    padding: 0.125rem;
    padding-left: 0.313rem;
    padding-right: 0.313rem;
    line-height: 1.25rem;

    p {
      float: left;
      color: $bluezodiac;
      text-transform: capitalize;
    }

    span {
      background-color: white;
      border-radius: 9.375rem;
      margin-left: 0.813rem;
      cursor: pointer;
      padding: 0.219rem;
    }
  }
}

.v3dp__datepicker {
  input {
    //   background-image: url('/assets/images/icons/Calendar.svg');
    background-repeat: no-repeat;
    background-position: 96% center;
  }
}

.v3dp__popout {
  .v3dp__heading__button {
    ::before {
      content: '';
      // background-image: url('/assets/images/icons/Calendar.svg');
    }
  }
}

.dropdown {
  .dropdown-menu {
    padding: 1rem 0;
    box-shadow: 0px 0px 1.5rem rgba(38, 38, 38, 0.05);
    border-radius: 0.25rem;
    border: 0;
    min-width: 8.813rem;

    a {
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding: 0.313rem 1.5rem;

      &:hover {
        font-weight: 600;
        background-color: #f3f3ff;
        color: $malibu;
      }
    }
  }
}

.providerrates {
  .one-rate {
    border-bottom: 0.063rem solid $aliceblue;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    &:last-child {
      border: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.provider-weeks {
  button {
    background-color: $aliceblue;
    color: $rockblue;

    &.selected {
      background-color: $demin;
      color: white;
    }
  }
}

.provider-schedule-selection {
  .selected {
    // background-color: $ghostwhite;

    .edit {
      display: block;
    }
  }

  .edit {
    display: none;
  }
}

.weeks-avaialablity-checkboxes {
  &#weeks-avaialablity-checkboxes {
    .one_day_time {
      border-bottom: 1px solid $aliceblue;

      &:last-child {
        border-bottom: 0;
      }

      .form-group {
        .form-controls {
          input {
            background-color: #f1f1f1;
            pointer-events: none;
            //   background-image: url('/assets/images/icons/Clock.svg');
            background-repeat: no-repeat;
            background-position: 1.125rem center;
            padding-left: 2.031rem;

            &::-webkit-calendar-picker-indicator {
              background: none;
            }
          }
        }
      }

      &.selected {
        .form-group {
          .form-controls {
            input {
              background-color: $white;
              pointer-events: initial;
            }
          }
        }
      }
    }
  }
}

.provider-profile-sidebar {
  #vertical-tabs.nav-tabs {
    .nav-link {
      .icon-box {
        min-width: 2.5rem;
      }
    }
  }
}

.form-group {
  .form-checkbox {
    label.fs-16 {
      font-size: 1rem;
      line-height: 1.625;

      &:before,
      &:after {
        margin-top: 0.313rem;
      }
    }
  }
}

.debit-card-icons {
  // background-image: url(/assets/images/debitcard-icons.svg);
  background-repeat: no-repeat;
  background-position: 98% center;
}

.vue-tel-input {
  border: 0.063rem solid #e3e5e5;
  border-radius: 0.25rem;

  .vti__input {
    padding: 0.688 1rem 0.688 1.25rem;
  }

  &:focus-within {
    border-color: $demin;
    outline: none;
    box-shadow: none;
  }
}

.drag-or-browse.file-hidden-upload {
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  }
}

.providers-listing {
  .provider-basic-info {
    .profile-data {
      max-width: 100%;
    }
  }
}

.find-care-filters {
  .form-group {
    margin-right: 1rem;

    .form-controls select {
      padding-right: 2.5rem;
    }
  }
}

.search-result-page {
  .search-card {
    padding: 1.438rem;

    input {
      outline: none;
    }

    .provider-address {
      border-right: 0.094rem solid $aliceblue;
      padding-right: 2.5rem;
      // margin-right: 2.5rem;
    }
  }

  .badge.progresss {
    // background-color: $ghostwhite1;
  }
}

.static-input-label {
  .form-group {
    .form-controls {
      .static-label {
        position: absolute;
        top: 0.75rem;
        left: 0.938rem;
      }

      input:not([type='radio']):not([type='checkbox']):not([type='submit']):not(
          [type='button']
        ):not([type='image']):not([type='file']) {
        padding-left: 3.75rem;
      }
    }
  }
}

.pswrd_validations {
  li {
    &.criteria-failed {
      span {
        color: $speechred;

        &::before {
          color: $speechred;
        }
      }
    }

    &.criteria-passed {
      span {
        color: green;

        &::before {
          color: green;
        }
      }
    }
  }
}

.select2-container--default {
  width: 100% !important;

  .select2-selection--single {
    //   background-image: url('/assets/images/Upsmall.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 0.938rem) center;
  }

  .select2-selection__arrow {
    display: none;
  }

  .select2-dropdown {
    border: 0.063rem solid #e0e0e0;

    ul.select2-results__options {
      padding-top: 1rem;

      li.select2-results__option {
        &[aria-selected='true'] {
          // background-color: $ghostwhite1;
          color: $bluezodiac;
          // background-image: url('/assets/images/icons/tickmark.svg');
          background-repeat: no-repeat;
          background-position: 90% center;
        }
      }
    }
  }

  &.select2 {
    border: 0.063rem solid #e3e5e5;
    border-radius: 0.25rem;
    padding: 0.688 1rem;

    .select2-selection {
      border: 0;
      outline: none;
    }
  }
}

.form-controls {
  .multiselect {
    border: 0.063rem solid #e3e5e5;
    border-radius: 0.25rem;
    padding: 1.94rem 1rem;
    outline: none;
    font-size: 1rem;

    &.is-active {
      box-shadow: none;
    }

    .multiselect-placeholder {
      margin-left: 0.688;
    }

    .multiselect-single-label {
      margin-left: 0.688;
    }

    .multiselect-caret {
      // background-image: url('/assets/images/Upsmall.svg');
      background-repeat: no-repeat;
      background-position: center;
      mask-image: none;
      background-color: transparent;
      width: 1.625;
      height: 0.688;
    }

    .multiselect-clear-icon {
      display: none;
    }

    .multiselect-options {
      padding-top: 1rem;

      .multiselect-option {
        padding: 1rem;

        span {
          font-size: 1rem;

          &:hover {
            // background-color: $ghostwhite1;
          }
        }

        &.is-selected {
          // background-color: $ghostwhite1;
          color: $bluezodiac;
          // background-image: url('/assets/images/icons/tickmark.svg');
          background-repeat: no-repeat;
          background-position: 90% center;
        }
      }
    }
  }
}

.services-dropdown {
  float: left;

  .togler {
    //   background-image: url('/assets/images/Upsmall.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 0.938rem) center;
    appearance: none;
    text-overflow: ellipsis;
    background-color: #ffffff;
    border: 0.063rem solid #e3e5e5;
    border-radius: 0.25rem;
    padding: 0.688 1rem;
    padding-right: 2.5rem;
    width: 100%;
    float: left;
    text-decoration: none;

    &:hover {
      color: $bluezodiac;
    }
  }

  .services-box {
    top: calc(100% + 0.313rem);
    left: 0;
    background-color: white;
    max-width: 42rem;
    width: 100vw;
    z-index: 1;
    padding: 2.5rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 1.438rem 0.063rem rgba(0, 0, 0, 0.05);

    input.search {
      padding-left: 5rem !important;
      // background-image: url('/assets/images/icons/Search.svg');
      background-repeat: no-repeat;
      background-position: 1.25rem center;
    }

    .divider {
      margin-top: 1.125rem;
      margin-bottom: 1.125rem;
      width: 100%;
      height: 0.063rem;
      background-color: $aliceblue;
    }

    .services-options {
      max-height: 20rem;
      overflow: scroll;
    }
  }
}

.max-width-100 {
  max-width: 100% !important;
}

.vue__time-picker {
  cursor: pointer;
  .dropdown {
    ul {
      li {
        &:not([disabled]) {
          &.active {
            background-color: $demin !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1190px) {
  .authcontent-outer {
    .notifications-banner {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .form-footer-full {
    .col-md-4 {
      order: 2rem;
      text-align: center;
      border-top: 0.063rem solid $aliceblue;
      padding-top: 1.5rem;
      margin-top: 1.5rem;
    }

    .col-md-8 {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
    }
  }

  .provider-profile-data {
    .tab-pane {
      padding-bottom: 6.25rem;
    }
  }

  .careauth-sidebar-outer {
    .provider-profile-sidebar {
      margin-top: 2.5rem;
    }
  }

  .search-result-page {
    .search-card {
      flex-flow: column;

      .provider-address {
        border: 0;
        padding-right: 0;
      }
    }
  }
}
