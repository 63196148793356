$bluezodiac: #384357;
$demin: #0d73bd;
$brightgray: #58595b;
$black: #000000;
$white: #fff;
$malibu: #52bdec;
$patternblue: #d4eefa;
$speechred: #c00000;
$rockblue: #94a3b8;
$aliceblue2: #f1f5f9;
$aliceblue: #f1f7fa;
$neurtral300: #cbd5e1;
$green: #0d9467;
$orange: #b45309;
$red: #b91c1c;
$danger: #f7dddc;
$success: #dcf3e4;
$termsBlue: #60bcea;
$darkBlue: #253950;

$primary-family: 'Poppins', sans-serif;
$signature-family: 'Reenie Beanie', cursive;

$primary-fs: 1rem;
$secondary-fs: 0.75rem;
$tertiary-fs: 0.75rem;

$menu-width: 270px;

// Media Quries
$breakpoint-xxxxl: 'only screen and (min-width: 3101px)';
$breakpoint-xxxl: 'only screen and (min-width: 2501px) and (max-width: 3100px)';
$breakpoint-xxl: 'only screen and (min-width: 1921px) and (max-width: 2500px)';
$breakpoint-xl: 'only screen and (min-width: 1200px) and (max-width:1440px)';
$breakpoint-lg: 'only screen and (min-width:992px) and (max-width:1199px)';
$breakpoint-lg-only: 'only screen and (min-width:1200px)';
$breakpoint-md-lg: 'only screen and (min-width:992px) and (max-width:1024px)';
$breakpoint-lg-max: 'only screen and (max-width:1199px)';
$breakpoint-md: 'only screen and (min-width:768px) and (max-width:991px)';
$breakpoint-sm: 'only screen and (min-width:576px) and (max-width:767px)';
$breakpoint-sm-only: 'only screen and (max-width:991px)';
$breakpoint-xs: 'only screen and (max-width:767px)';
$breakpoint-xxs: 'only screen and (max-width: 375px)';
