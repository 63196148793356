.icon-arrowsolid {
  font-size: 8px;
  display: inline-block;
  &::before {
    content: '\e920';
  }
  &.down {
    transform: rotate(180deg);
  }
}

.icon-iconlogo:before {
  content: '\e91c';
  color: #58595b;
}
.icon-logout:before {
  content: '\e91b';
}
.icon-bellnoti:before {
  content: '\e99f';
  color: #58595b;
}
.icon-cash-payment-coin:before {
  content: '\e9a0';
  color: #58595b;
}
.icon-commonfiles:before {
  content: '\e9a1';
  color: #58595b;
}
.icon-Group:before {
  content: '\e9a2';
  color: #58595b;
}
.icon-groupusers:before {
  content: '\e9a3';
  color: #58595b;
}
.icon-mulpusers:before {
  content: '\e9a4';
  color: #58595b;
}
.icon-Provider1:before {
  content: '\e9a5';
  color: #58595b;
}
.icon-userfiles:before {
  content: '\e9a6';
  color: #58595b;
}
.icon-usersettings:before {
  content: '\e9a7';
  color: #58595b;
}
.icon-Star-halffill .path1:before {
  content: '\e960';
  color: rgb(234, 185, 28);
}
.icon-Star-halffill .path2:before {
  content: '\e961';
  margin-left: -1em;
  color: rgb(227, 229, 229);
}
.icon-Chat-filled:before {
  content: '\e931';
  color: #942093;
}
.icon-Payouts-filled:before {
  content: '\e945';
  color: #942093;
}
.icon-dropdowndot:before {
  content: '\e932';
  color: #ababab;
}
.icon-dropdowndot1:before {
  content: '\e933';
  color: #797c7f;
}
.icon-Aboutme:before {
  content: '\e934';
  color: #797c7f;
}
.icon-AccountType:before {
  content: '\e935';
  color: #797c7f;
}
.icon-Add:before {
  content: '\e936';
  color: #797c7f;
}
.icon-Address-and-Timezone:before {
  content: '\e937';
  color: #797c7f;
}
.icon-Alert1:before {
  content: '\e938';
  color: #797c7f;
}
.icon-Application-Review-Unfilled:before {
  content: '\e939';
  color: #797c7f;
}
.icon-Audio:before {
  content: '\e93a';
  color: #797c7f;
}
.icon-Availability:before {
  content: '\e93b';
  color: #797c7f;
}
.icon-BasicInfo:before {
  content: '\e93c';
  color: #797c7f;
}
.icon-BookATime-filled:before {
  content: '\e93d';
  color: #942093;
}
.icon-BookATime-Unfilled:before {
  content: '\e93e';
  color: #797c7f;
}
.icon-Booking-filled:before {
  content: '\e93f';
  color: #942093;
}
.icon-Booking-Unfilled:before {
  content: '\e940';
  color: #797c7f;
}
.icon-Calendar1:before {
  content: '\e941';
  color: #797c7f;
}
.icon-CareGoals:before {
  content: '\e942';
  color: #797c7f;
}
.icon-Certifications:before {
  content: '\e943';
  color: #797c7f;
}
.icon-Characters:before {
  content: '\e944';
  color: #797c7f;
}
.icon-Chat:before {
  content: '\e946';
  color: #942093;
}
.icon-Chat-Unfilled:before {
  content: '\e947';
  color: #797c7f;
}
.icon-Clients-filled:before {
  content: '\e948';
  color: #942093;
}
.icon-Clients-Unfilled:before {
  content: '\e949';
  color: #797c7f;
}
.icon-Clock:before {
  content: '\e94a';
  color: #797c7f;
}
.icon-Consentauthorization:before {
  content: '\e94b';
  color: #797c7f;
}
.icon-Consent-Privacy-policy-Unfilled:before {
  content: '\e94c';
  color: #00137f;
}
.icon-Consenttosharing:before {
  content: '\e94d';
  color: #797c7f;
}
.icon-Cross:before {
  content: '\e94e';
}
.icon-Dashboard-filled:before {
  content: '\e94f';
  color: #942093;
}
.icon-Dashboard-Unfilled:before {
  content: '\e950';
  color: #797c7f;
}
.icon-Delete:before {
  content: '\e951';
  color: #797c7f;
}
.icon-Demographics:before {
  content: '\e952';
  color: #797c7f;
}
.icon-Doc .path1:before {
  content: '\e953';
  color: rgb(0, 19, 127);
}
.icon-Doc .path2:before {
  content: '\e996';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Doc .path3:before {
  content: '\e997';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Doc .path4:before {
  content: '\e998';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Doc2:before {
  content: '\e954';
  color: #797c7f;
}
.icon-Document-Unfilled:before {
  content: '\e955';
  color: #797c7f;
}
.icon-Download:before {
  content: '\e956';
  color: #942093;
}
.icon-Downsmall:before {
  content: '\e957';
  color: #797c7f;
}
.icon-Edit1:before {
  content: '\e958';
  color: #797c7f;
}
.icon-Edit-Small:before {
  content: '\e959';
  color: #797c7f;
}
.icon-Email:before {
  content: '\e95a';
  color: #797c7f;
}
.icon-EmailVerification:before {
  content: '\e95b';
  color: #797c7f;
}
.icon-emoji:before {
  content: '\e95c';
  color: #797c7f;
}
.icon-Error:before {
  content: '\e95d';
  color: #797c7f;
}
.icon-Filter:before {
  content: '\e95e';
  color: #797c7f;
}
.icon-Heart-filled:before {
  content: '\e95f';
  color: #942093;
}
.icon-Heart-Unfilled:before {
  content: '\e962';
  color: #797c7f;
}
.icon-Image1:before {
  content: '\e963';
}
.icon-Img .path1:before {
  content: '\e964';
  color: rgb(192, 0, 0);
}
.icon-Img .path2:before {
  content: '\e999';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Info1:before {
  content: '\e965';
  color: #797c7f;
}
.icon-Integration:before {
  content: '\e966';
  color: #797c7f;
}
.icon-InvoicePayment:before {
  content: '\e967';
  color: #797c7f;
}
.icon-Language-and-Introduction:before {
  content: '\e968';
  color: #797c7f;
}
.icon-Leftlarge:before {
  content: '\e969';
  color: #797c7f;
}
.icon-Link:before {
  content: '\e96a';
  color: #797c7f;
}
.icon-Mail:before {
  content: '\e96b';
  color: #797c7f;
}
.icon-MedicalHistory:before {
  content: '\e96c';
  color: #797c7f;
}
.icon-Message:before {
  content: '\e96d';
  color: #797c7f;
}
.icon-Minus:before {
  content: '\e96e';
}
.icon-Not-View:before {
  content: '\e96f';
}
.icon-Notification-filled:before {
  content: '\e970';
  color: #942093;
}
.icon-Notification-Unfilled:before {
  content: '\e971';
  color: #797c7f;
}
.icon-Numaric:before {
  content: '\e972';
  color: #797c7f;
}
.icon-PaymentInfo:before {
  content: '\e973';
  color: #797c7f;
}
.icon-Payment-Settings:before {
  content: '\e974';
  color: #797c7f;
}
.icon-Payouts-Unfilled:before {
  content: '\e975';
  color: #797c7f;
}
.icon-PDF .path1:before {
  content: '\e976';
  color: rgb(192, 0, 0);
}
.icon-PDF .path2:before {
  content: '\e99a';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PDF .path3:before {
  content: '\e99b';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PDF .path4:before {
  content: '\e99c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Pdf2:before {
  content: '\e977';
  color: #797c7f;
}
.icon-Phone:before {
  content: '\e978';
  color: #797c7f;
}
.icon-Plus:before {
  content: '\e979';
}
.icon-POA .path1:before {
  content: '\e97a';
  color: rgb(243, 243, 255);
}
.icon-POA .path2:before {
  content: '\e99d';
  margin-left: -1em;
  color: rgb(148, 32, 147);
}
.icon-Public-User:before {
  content: '\e97b';
  color: #797c7f;
}
.icon-Rates:before {
  content: '\e97c';
  color: #797c7f;
}
.icon-Resend:before {
  content: '\e97d';
  color: #797c7f;
}
.icon-RightArrow-Large:before {
  content: '\e97e';
  color: #00137f;
}
.icon-RightArrow-Small:before {
  content: '\e97f';
  color: #942093;
}
.icon-Rightlarge:before {
  content: '\e980';
  color: #797c7f;
}
.icon-Search1:before {
  content: '\e981';
  color: #797c7f;
}
.icon-SearchforCare:before {
  content: '\e982';
  color: #797c7f;
}
.icon-SearchforCare-filled:before {
  content: '\e983';
  color: #942093;
}
.icon-SecurityQuestions:before {
  content: '\e984';
  color: #797c7f;
}
.icon-Services:before {
  content: '\e985';
  color: #797c7f;
}
.icon-SetPassword:before {
  content: '\e986';
  color: #797c7f;
}
.icon-Small-Cross .path1:before {
  content: '\e987';
  color: rgb(255, 255, 255);
}
.icon-Small-Cross .path2:before {
  content: '\e99e';
  margin-left: -1em;
  color: rgb(38, 38, 38);
}
.icon-Specialcharacter:before {
  content: '\e988';
  color: #797c7f;
}
.icon-Star-fill:before {
  content: '\e989';
  color: #eab91c;
}
.icon-Star-Unfill:before {
  content: '\e98a';
  color: #e3e5e5;
}
.icon-Success:before {
  content: '\e98b';
  color: #797c7f;
}
.icon-tickmark:before {
  content: '\e98c';
  color: #00137f;
}
.icon-twoFA:before {
  content: '\e98d';
  color: #797c7f;
}
.icon-Updateicon:before {
  content: '\e98e';
  color: #942093;
}
.icon-UperLowercase:before {
  content: '\e98f';
  color: #797c7f;
}
.icon-Upsmall:before {
  content: '\e990';
  color: #797c7f;
}
.icon-User:before {
  content: '\e991';
  color: #797c7f;
}
.icon-Vaccination-Status:before {
  content: '\e992';
  color: #797c7f;
}
.icon-View:before {
  content: '\e993';
  color: #797c7f;
}
.icon-ZoomIn:before {
  content: '\e994';
}
.icon-ZoomOut:before {
  content: '\e995';
}
.icon-Address-Timezone:before {
  content: '\e901';
  color: #58595b;
}
.icon-Account-Type:before {
  content: '\e900';
  color: #0d73bd;
}
.icon-Alert .path1:before {
  content: '\e902';
  color: rgb(13, 115, 189);
}
.icon-Alert .path2:before {
  content: '\e903';
  margin-left: -1em;
  color: rgb(20, 138, 223);
}
.icon-Alert .path3:before {
  content: '\e904';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Alert .path4:before {
  content: '\e905';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Alphabet:before {
  content: '\e906';
  color: #94a3b8;
}
.icon-Areas-of-Expertise:before {
  content: '\e907';
  color: #58595b;
}
.icon-Arrow-Right:before {
  content: '\e908';
}
.icon-Basic-Info:before {
  content: '\e909';
  color: #58595b;
}
.icon-Calendar:before {
  content: '\e90a';
}
.icon-Cancel:before {
  content: '\e90b';
}
.icon-Chevron-Down:before {
  content: '\e90c';
}
.icon-Chevron-Up:before {
  content: '\e90d';
}
.icon-Edit .path1:before {
  content: '\e90e';
  color: rgb(0, 19, 127);
}
.icon-Edit .path2:before {
  content: '\e90f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Edit .path3:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Edit .path4:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Email-Verification:before {
  content: '\e912';
  color: #58595b;
}
.icon-Eye-Off:before {
  content: '\e913';
  color: $black;
}
.icon-Eye-On:before {
  content: '\e914';
  color: $black;
}
.icon-File-2:before {
  content: '\e915';
  color: #0d73bd;
}
.icon-File-ThinStroke:before {
  content: '\e916';
  color: #58595b;
}
.icon-File:before {
  content: '\e917';
  color: #0d73bd;
}
.icon-Image:before {
  content: '\e918';
  color: #0d73bd;
}
.icon-Info:before {
  content: '\e919';
  color: #94a3b8;
}
.icon-Numeric:before {
  content: '\e91a';
  color: #94a3b8;
}
.icon-Provider .path1:before {
  content: '\e91b';
  color: rgb(241, 247, 250);
}
.icon-Provider .path2:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(13, 115, 189);
}
.icon-Provider .path3:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(13, 115, 189);
}
.icon-Provider .path4:before {
  content: '\e91e';
  margin-left: -1em;
  color: rgb(82, 189, 236);
}
.icon-Provider .path5:before {
  content: '\e91f';
  margin-left: -1em;
  color: rgb(82, 189, 236);
}
.icon-Provider .path6:before {
  content: '\e920';
  margin-left: -1em;
  color: rgb(255, 210, 210);
}
.icon-Provider .path7:before {
  content: '\e921';
  margin-left: -1em;
  color: rgb(255, 199, 199);
}
.icon-Provider .path8:before {
  content: '\e922';
  margin-left: -1em;
  color: none;
}
.icon-Public-Info:before {
  content: '\e923';
  color: #94a3b8;
}
.icon-Search:before {
  content: '\e924';
}
.icon-Special:before {
  content: '\e925';
  color: #94a3b8;
}
.icon-Status:before {
  content: '\e926';
  color: #58595b;
}
.icon-Successful .path1:before {
  content: '\e927';
  color: rgb(13, 115, 189);
}
.icon-Successful .path2:before {
  content: '\e928';
  margin-left: -1em;
  color: rgb(20, 138, 223);
}
.icon-Successful .path3:before {
  content: '\e929';
  margin-left: -1em;
  color: rgb(20, 138, 223);
}
.icon-Successful .path4:before {
  content: '\e92a';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Terms-Conditions:before {
  content: '\e92b';
  color: #58595b;
}
.icon-Tick-Large:before {
  content: '\e92c';
  color: #0d73bd;
}
.icon-Upload-File .path1:before {
  content: '\e92d';
  color: rgb(13, 115, 189);
}
.icon-Upload-File .path2:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(13, 115, 189);
}
.icon-Upload-File .path3:before {
  content: '\e92f';
  margin-left: -1em;
  color: rgb(82, 189, 236);
}
.icon-Vertical-Dots:before {
  content: '\e930';
}
.icon-Expense-Management:before {
  content: '\e91d';
}
.icon-TimeSheets:before {
  content: '\e91e';
  color: #58595b;
}
.icon-payments:before {
  content: '\e91f';
}
.icon-bonus:before {
  content: '\e922';
}
.icon-ProfileSettings:before {
  content: '\e921';
}
