@import 'common';

body {
  font-family: $primary-family;
  font-size: $primary-fs;
  color: $bluezodiac;
  font-weight: normal;
  position: relative;
  overflow-x: hidden;
  line-height: 1.625;
  min-height: 100vh;
  margin: 0;
}

h1,
.h1,
h2,
.h2,
h4,
.h4,
h5,
.h5 {
  font-weight: bold;
  color: $black;
}

h3 {
  font-weight: 600;
  color: $black;
}

h1,
.h1 {
  font-size: 2rem;
}
h2,
.h2 {
  font-size: 1.5rem;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1rem;
}
h5,
.h5 {
  font-size: 0.75rem;
}
.p2 p {
  font-size: $secondary-fs;
}
.p3 p {
  font-size: $secondary-fs;
  font-weight: bold;
}
.p4 p,
.p4 span {
  font-size: $tertiary-fs;
  color: $rockblue;
}
.p5 p,
.p5 span {
  font-size: $tertiary-fs;
  // font-weight: bold;
  color: $rockblue;
}
.signature-font {
  font-family: $signature-family;
}
.signature-display {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
}
.alert {
  border-radius: 8px;
  border: 0;
  * {
    color: $brightgray;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  .content {
    margin: 0;
    * {
      font-size: 12px;
    }
  }
  &.alert-success {
    background: $success;
  }
  &.alert-dager {
    background: $danger;
  }
}
.btn {
  font-size: $primary-fs;
  font-weight: 700;
  border: none;
  // border-radius: 3.125rem;
  // min-width: 7.438;
  padding: 0.75rem 2rem;
  &.btn-md {
    padding: 0.75rem 3.75rem;
  }
  &.btn-sm {
    padding: 0.3rem 0.75rem;
  }
  &.btn-primary {
    background-color: $demin;
    color: $white;
    &:hover {
      // background-color: $malibu;
      box-shadow: 8px 8px 16px rgba(0, 19, 127, 0.25);
    }

    &:disabled {
      background-color: $aliceblue;
      color: $rockblue;
      color: $rockblue !important;
      border: 1px solid $aliceblue !important;
    }
    &.disabled {
      pointer-events: none;
      background: $aliceblue;
      color: $rockblue !important;
      border: 1px solid $aliceblue !important;
    }
    &.btn-outline {
      background-color: transparent;
      color: $demin;
      border: 1px solid $demin;
      border-radius: 4px;
    }
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &.btn-text {
    background-color: transparent;
    color: $demin;
    border: 1px solid $demin;
    i,
    span {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
    &:hover {
      background-color: $white;
      box-shadow: 0.313rem 0.313rem 1rem rgba(0, 19, 127, 0.25);
    }
  }

  &.btn-red {
    color: $speechred;
    // background-color: $cosmos;
    &:hover {
      box-shadow: 0.313rem 0.313rem 1rem rgba(192, 0, 0, 0.25);
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.z-index1 {
  z-index: 1 !important;
}
.form-group {
  .input-group {
    flex-wrap: nowrap;
    &.focused {
      .input-group-text {
        border: 0.063rem solid $bluezodiac;
        border-right: 0;
      }
    }
    .input-group-text {
      background-color: transparent;
      border: 0.063rem solid $rockblue;
      border-right: 0;
      padding-right: 0;

      + input {
        border-left: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  margin-bottom: 1.5rem;
  label {
    margin-bottom: 0.25rem;
    color: $black;
  }
  .label-icon {
    display: flex;
    justify-content: space-between;
  }
  .radio-wrapper {
    display: flex;
  }
  .react-tel-input {
    .form-control {
      padding: 0.625rem 0.625rem !important;
      height: auto;
      padding-left: 50px !important;
      font-size: 16px;
    }
    .flag-dropdown {
      border: 0.063rem solid #94a3b8;
    }
  }
  .form-controls {
    position: relative;
    select,
    textarea,
    input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not(
        [type='image']
      ):not([type='file']) {
      background-color: transparent;
      border: 0.063rem solid $rockblue;
      border-radius: 0.25rem;
      padding: 0.625rem 0.625rem;
      width: 100%;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $brightgray;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $brightgray;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $brightgray;
      }
      &:focus {
        border-color: $bluezodiac;
        outline: none;
        box-shadow: none;
      }

      &.double-icon {
        padding: 0.688 2.813rem;
      }
      &.left-icon {
        padding: 0.688rem 1rem 0.688rem 3.125rem;
      }
      &.right-icon {
        padding: 0.688rem 3.125rem 0.688rem 1rem;
      }
      &:disabled {
        background-color: #f1f1f1 !important;
      }
      &:read-only {
        background-color: #f1f1f1 !important;
        color: $rockblue;
      }
      &.searchfield {
        &:read-only {
          background-color: white !important;
          &:disabled {
            background-color: #f1f1f1 !important;
          }
        }
      }
    }
    span.text {
      position: absolute;
      top: 0.688;
      left: 0.938rem;
    }
    span.ico {
      position: absolute;
      font-size: 1.5rem;
      top: 0.781rem;
      right: 0.938rem;
      z-index: -1;
    }
    span.icoleft {
      position: absolute;
      font-size: 1.5rem;
      top: 0.781rem;
      left: 0.938rem;
    }
    select {
      background-image: url('/assets/images/Downsmall-purple.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 0.938rem) center;
      appearance: none;
      text-overflow: ellipsis;
    }
  }
  textarea {
    resize: none;
  }
  .form-checkbox {
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox']:disabled + label {
      color: $rockblue;
      cursor: default;
    }
    input[type='checkbox']:disabled + label::before {
      border: 0.16rem solid $rockblue;
    }
    label {
      position: relative;
      padding-left: 2.4rem;
      line-height: 1.634rem;
      font-size: $primary-fs;
      margin-bottom: 0;
      width: 100%;
      cursor: pointer;
      width: auto;
      color: $black;
      a {
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    label:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1.5px solid $rockblue;
      border-radius: 0.25rem;
      position: absolute;
      top: 0px;
      left: 0px;
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
    label:after {
      content: '';
      border: solid $bluezodiac;
      border-width: 0 2px 2px 0;
      position: absolute;
      top: 4px;
      left: 0.5rem;
      height: 10px;
      width: 5px;
      transition: all 0.3s;
      transform: rotate(45deg);
      opacity: 0;
    }
    input:checked + label {
      color: $bluezodiac;
    }
    input:checked + label::before {
      border: none;
      background-color: $demin;
    }
    input:checked + label:after {
      opacity: 1;
      border-color: $white;
    }
  }
  .custom_radio {
    input[type='radio'] {
      display: none;
    }
    input[type='radio'] + label {
      position: relative;
      display: inline-block;
      padding-left: 2.4rem;
      margin-right: 2rem;
      cursor: pointer;
      line-height: 1.634rem;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      font-size: $primary-fs;
      color: $bluezodiac;
      width: auto;
      outline: none;
    }
    input[type='radio'] + label:before,
    input[type='radio'] + label:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
      text-align: center;
      color: white;
      font-family: Times;
      border-radius: 50%;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    input[type='radio'] + label:before {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      box-shadow: inset 0 0 0 1.5px white, inset 0 0 0 1rem white;
      border: 0.16rem solid $rockblue;
    }
    input[type='radio']:checked + label:before {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      box-shadow: inset 0 0 0 0.15rem white, inset 0 0 0 1rem $demin;
      border: 0.16rem solid $demin;
    }
    input[type='radio']:disabled + label {
      color: $rockblue;
      cursor: default;
    }
    input[type='radio']:disabled + label::before {
      border: 0.16rem solid $rockblue;
    }
  }
}

.notifications-banner {
  border-radius: 0.313rem;
  padding: 0.688 1rem 0.688 1.5rem;
  color: $white;
  max-width: 71.625rem;
  width: 100%;
  margin: 1rem auto 1.5rem;
  .notif-icon {
    margin-right: 0.75rem;
    span {
      font-size: 1.25rem;
      &::before {
        color: $white;
      }
    }
  }
  .notif-msg {
    margin-right: 1rem;
    p {
      span,
      a {
        font-weight: 700;
      }
      a {
        color: $white;
      }
    }
  }
  .notif-cross {
    span {
      display: inline-block;
      &:hover {
        cursor: pointer;
        transform: rotate(180deg);
      }
    }
  }
  &.progresss {
    background-color: $demin;
  }
  &.success {
    background-color: $malibu;
  }
  &.errorr {
    background-color: $speechred;
  }
  &.info {
    background-color: $patternblue;
    color: $bluezodiac;
    .notif-msg {
      a {
        color: $bluezodiac;
      }
    }
    span:before {
      color: $bluezodiac;
    }
  }
}

.badge-icon {
  // background-color: $ghostwhite1;
  padding: 0.313rem 1rem;
  border-radius: 0.25rem;
  // border: 0.063rem solid $ghostwhite1;
  font-size: $secondary-fs;
  &.badge-flex {
    display: flex;
    align-items: center;
  }
  i,
  span {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
}
.badge {
  border-radius: 100px;
  font-weight: 400;
  padding-top: 6px;
  padding-bottom: 6px;
  &.bg-primary {
    line-height: 16px;
    background-color: $demin !important;
    border-radius: 100px;
  }
}

.anchor {
  font-weight: 700;
  font-size: $tertiary-fs;
  text-decoration: none;
  &.a-line {
    color: $bluezodiac;
    &:hover {
      color: $black;
    }
  }
  &.a-icon,
  &.a-arrow {
    color: $malibu;
    display: flex;
    align-items: center;
    i,
    span {
      font-size: 1.5rem;
      margin-right: 0.25rem;
      &::before {
        color: $malibu;
      }
    }
  }
  &.a-simple {
    color: $bluezodiac;
  }
  &.a-icon,
  &.a-simple {
    &:hover {
      color: $bluezodiac;
      i::before,
      span::before {
        color: $bluezodiac;
      }
    }
  }
  &.a-arrow {
    span:before {
      display: inline-block;
      transform: rotate(180deg);
    }
    &:hover {
      i,
      span {
        transform: translateX(-5px);
      }
    }
  }
}

//loader
.load-more {
  text-align: center;
  max-width: 170px;
  width: 100%;
  padding: 0.688 0rem;
  // background-color: $ghostwhite1;
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  // Loader
  .loader {
    position: relative;
    margin: auto 0.313rem 0.313rem 0rem;
    height: 1.25rem;
    width: 2.5rem;
    & > div,
    &:before,
    &:after {
      animation: load 1.4s ease-in-out infinite;
      background-color: $demin;
      border-radius: 50%;
      content: '';
      height: 0.25rem;
      width: 0.25rem;
    }

    & > div {
      animation-delay: 0.3s;
      left: 1.125rem;
      top: 0.25rem;
      position: absolute;
    }

    &:before {
      left: 0.75rem;
      top: 0.25rem;
      position: absolute;
    }

    &:after {
      animation-delay: 0.6s;
      left: 1.5rem;
      top: 0.25rem;
      position: absolute;
    }
  }
  h5 {
    color: $demin;
    margin-bottom: 0;
  }
}
@keyframes load {
  50% {
    transform: translateY(0.4em);
  }
}

// super admin view provider top navigation tabs
#vertical-tabs.nav-tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 0;
  padding: 0.5rem;
  background-color: $aliceblue;
  border-radius: 0.25rem;
  flex-wrap: wrap;
  .nav-item {
    flex: 1;
    border-radius: 0.25rem;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  .nav-link {
    color: $brightgray;
    position: relative;
    padding: 0.5rem;
    width: 100%;
    white-space: nowrap;
    .line {
      width: 0.081rem;
      background-color: $rockblue;
      height: 1.875rem;
      position: relative;
      left: 1.188rem;
      margin-top: 0.313rem;
    }
    .icon-box {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.313rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      span {
        font-size: 1.5rem;
        &:nth-child(2) {
          display: none;
        }
      }
    }
    &.active,
    &:hover,
    &.router-link-active,
    &.completed {
      background-color: $malibu;
      border-color: transparent;
      color: $white;
      border-radius: 0.25rem;
      .tabname {
        -webkit-text-stroke: 1.1px;
        // font-weight: 400;
      }

      .line {
        background-color: $demin;
      }
      .icon-box {
        border: 0.063rem solid $demin;
        background-color: $demin;
        span::before {
          color: $white;
        }
      }
    }

    &.completed {
      .icon-box {
        background-color: transparent;
        span {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
        }
        span::before {
          color: $demin;
        }
      }
    }
  }
}
// provider side navigation tabs
#vertical-tabs2.nav-tabs {
  display: block;
  border-bottom: 0;
  .nav-link {
    color: $brightgray;
    position: relative;
    padding: 0 0 0.313rem;
    width: 100%;
    .line {
      width: 0.081rem;
      background-color: $rockblue;
      height: 1.875rem;
      position: relative;
      left: 1.188rem;
      margin-top: 0.313rem;
    }
    .icon-box {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.313rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      // border: 0.063rem solid $rockblue;
      span {
        font-size: 1.5rem;
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .border-gray {
      border: 0.063rem solid $rockblue;
    }
    .border-blue {
      border: 0.063rem solid $demin;
    }
    &.active,
    &:hover,
    &.router-link-active,
    &.completed {
      background-color: transparent;
      border-color: transparent;
      color: $bluezodiac;
      .tabname {
        -webkit-text-stroke: 1.2px;
      }

      .line {
        background-color: $demin;
      }
      .icon-box {
        border: 0.063rem solid $demin;
        background-color: $demin;
        span::before {
          color: $white;
        }
      }
    }

    &.completed {
      .icon-box {
        background-color: transparent;
        span {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
        }
        span::before {
          color: $demin;
        }
      }
    }
  }
}

#horizontal-tabs.nav-tabs {
  border-bottom: 0;
  .nav-item {
    display: flex;
    max-width: 25%;
    width: 100%;
    background-color: $aliceblue2;
    padding: 0.25rem 0.125rem;
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      padding-left: 0.25rem;
    }
    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      padding-right: 0.25rem;
    }
  }
  .nav-link {
    width: 100%;
    border: none;
    padding: 0.688 0rem;
    color: $bluezodiac;
    &.active,
    &:hover {
      background-color: $malibu;
      color: $white;
      border-radius: 0.25rem;
      font-weight: 700;
    }
  }
  &.admin-tabs {
    .nav-item {
      max-width: 19%;
      &:nth-child(3) {
        max-width: 24%;
      }
    }
  }
}

//msgs
.msgs {
  h4 {
    margin-bottom: 1rem;
    img {
      margin-right: 1rem;
    }
  }
}

.progress {
  min-width: 8.313rem;
  // background-color: $snuff;
  height: 0.313rem;
  border-radius: 6.25rem;
  .progress-bar {
    background-color: $malibu;
    border-radius: 6.25rem;
  }
}

.modal {
  .modal-content {
    border-radius: 16px;
    border: none;
    .modal-body {
      padding: 80px;
      position: relative;
      form {
        .btn {
          margin-top: 12px;
        }
      }
      .btn {
        padding: 7px 0px;
        border: 1px solid $demin;
        &:disabled {
          border: 1px solid transparent;
        }
      }

      .dropdown-toggle {
        border: 0;
      }
      .icon-Cross {
        position: absolute;
        top: 1.813rem;
        right: 1.813rem;
        display: inline-block;
        cursor: pointer;
        &:hover {
          transform: rotate(180deg);
        }
      }
      &.modal-4 {
        padding: 2.5rem;
      }
      table {
        tr {
          td {
            padding: 0.5rem 1rem 0.5rem 0;
          }
        }
      }
    }
  }
  &.shrinkmodal {
    .modal-body {
      padding: 40px;
    }
  }
}

//inline-ul
.inline-ul {
  @include custom-ul;
  li {
    display: inline-block;
  }
}

.care-dropmenu {
  a.dropdown-toggle {
    color: inherit;
    text-decoration: none;
  }
  .dropdown-menu {
    box-shadow: 0rem 0rem 1.5rem rgba(38, 38, 38, 0.05);
    border-radius: 0.25rem;
    border: none;
    padding: 1rem 0;
    min-width: 8.813rem;
    .dropdown-item {
      padding: 0.313rem 1.5rem;
      font-size: $secondary-fs;
      color: $bluezodiac;
      &:hover {
        // background-color: $ghostwhite1;
        color: $malibu;
        font-weight: 700;
      }
    }
  }
}

//accordion
.accordion {
  .accordion-item {
    border: none;
    border-radius: 0;
    margin-bottom: 24px;
    &:first-child {
      margin-top: 16px;
    }
    .accordion-header {
      border-bottom: 1px solid $rockblue;
      .accordion-button {
        // background-color: $ghostwhite1;
        border-radius: 0.25rem;
        // border: 0.063rem solid $ghostwhite1;
        color: $demin;
        padding: 12px 16px 0px 0px;
        box-shadow: none;
        color: $bluezodiac;
        font-size: 16px;
        font-weight: 700;
        &::after {
          background-size: 1.563rem;
          //   background-image: url('/assets/images/Downsmall-purple.svg');
          width: 1.563rem;
          height: 1.563rem;
          transform: rotate(0deg);
        }
        &:not(.collapsed) {
          background-color: white;
        }
        &:not(.collapsed)::after {
          transform: rotate(180deg);
        }
      }
    }
  }
  .accordion-body {
    padding: 16px;
    background-color: $aliceblue2;
  }
}
.custom-error {
  &.success {
    color: $demin;
    span:before {
      color: $demin;
    }
  }
}

.dropzone {
  background-color: white;
  border: 1px solid $rockblue;
  border-radius: 4px;
  padding: 16px 14px 16px 24px;
  clear: both;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .filesize {
    margin-right: 80px;
  }
  &.finalupload {
    padding: 13px 20px 12px 20px;
    background-color: $patternblue;
    display: flex;
    align-items: center;
    border: 0;
    // position important given to override modal icon-Cross position absolute
    .icon-Cross {
      position: initial !important;
    }
  }
  p {
    font-size: 12px;
  }
  .p2 {
    font-size: 12px;
    color: $rockblue;
    text-align: left;
  }
}

.global-file-style {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-browse {
  background-color: $white;
  color: $demin;
  text-decoration: none;
  font-weight: 700;
  border-radius: 4px;
  padding: 8px 24px;
  border: 0;
  &:hover {
    // color: $secondary;
    text-decoration: none;
    cursor: pointer;
  }
}

.table > thead {
  position: sticky;
  background-color: $white;
  z-index: 1;
  top: 0;
}
.table > thead > tr > th {
  padding: 1rem 1rem 0.75rem 0;
}

.stickycolumn {
  position: sticky;
  right: 0;
  text-align: center;
  padding-right: 0 !important;
  min-width: auto !important;

  .table-dropdown {
    border-left: 1px solid #dee2e6;
    background-color: $white;
  }
}
th.stickycolumn {
  background-color: $white;
}

.table > :not(caption) > * > * {
  padding: 0.07rem 15px 0 0;
  font-size: 12px;
  vertical-align: middle;
}

.table-dropdown {
  .dropdown-menu {
    padding: 0 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
    min-width: 10.438rem !important;
    z-index: 9999;
    a {
      padding: 0.5rem !important;
      font-size: 0.75rem !important;
    }
    a:hover {
      background-color: $patternblue !important;
      color: $black !important;
      font-weight: 400 !important;
    }
  }
  .btn {
    background-color: $white;
    color: $black;
    padding: 0.5rem;
    font-size: 1.25rem;
    &:first-child:active {
      background-color: $white;
      color: $black;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
}

.weeklyTotal.bg-aliceblue > td {
  background-color: $aliceblue;
}

.weeklyTotal.bg-lightgray > td {
  background-color: rgba(50, 50, 52, 0.03);
}
