//min576
@media (min-width: 576px) {
  .shrinkmodal {
    .modal-dialog {
      max-width: 630px;
    }
  }
  .modal-dialog {
    max-width: 710px;
  }
  .modal-terms-condition,
  .modal-choosepayrate,
  .modal-codeverification {
    .modal-dialog {
      max-width: 616px;
    }
  }

  .tabname {
    font-size: 16px;
  }
}
//max375
@media #{$breakpoint-xxs} {
  .authcontent-outer .authcontent-inner {
    .file-upload {
      .progress {
        min-width: 6.25rem;
      }
      .p5 span {
        max-width: 8.125rem;
      }
    }
  }
}
//max575
@media (max-width: 575.98px) {
  footer {
    z-index: 1;
  }
  // super admin view provider top navigation tabs
  .superadmin-provider-wrapper {
    .edit-profile-pic {
      text-align: center;
      .avatar-img + .ml-29 {
        margin: 0;
        margin-top: 12px;
      }
      // using + because we are accessing btn that is outside of the edit-profile-pic
      + .btn {
        width: 100%;
        margin-top: 12px;
      }
    }
  }
  .paginated-info {
    text-align: right;
    margin-bottom: 12px;
  }
}
//max767
@media #{$breakpoint-xs} {
  html {
    font-size: 14px;
  }

  .date-picker-time-select .react-datepicker {
    width: 385px;
  }
  // avatar image in super admin header
  .loggedin-user div {
    div {
      margin-right: 0;
    }
  }
  .authcontent-outer {
    padding: 2.5rem 0rem 1.875rem 0rem;
  }
  .plr-70 {
    padding: 0rem 1rem;
  }
  .bg-before::before {
    display: none;
  }
  .authcontent-inner {
    margin: auto;
    margin-top: 3.125rem;
  }
  .careauth-sidebar-outer {
    height: 30rem;
    overflow-y: scroll;
    .careauth-sidebar-inner {
      padding: 2.5rem 0 10.938rem;
    }
  }
  .mbl-shadow {
    background: linear-gradient(180deg, rgba(243, 243, 255, 0) 0%, #f3f3ff 65.89%);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 6.875rem;
  }
  .nav-tabs.mt-60 {
    margin-top: 2.5rem;
  }
  .btn {
    padding: 0.688 0.938rem;
    &.mr-24 {
      margin-right: 0.875rem;
    }
  }
  .profile-main {
    padding: 4.75rem 1.25rem 0rem !important;
  }
  .carecard {
    padding: 1.25rem;
  }
  .provider-basic-info .profile-data-ul li {
    display: block;
    border-right: none;
  }
  .provider-detail .badges-ul li {
    margin-bottom: 1.125rem;
  }
  .buttons-div {
    button {
      margin-bottom: 1rem;
    }
  }
  .profile-img {
    margin-bottom: 1rem;
  }
  .care-tabs-mbl {
    position: relative;
    display: block;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    #horizontal-tabs.nav-tabs {
      display: inline-block;
      white-space: nowrap;
      width: 100%;
      .nav-item {
        display: inline-block;
        margin-left: -0.25rem;
        margin-bottom: 0;
        width: 100%;
        max-width: 40%;
      }
      &.admin-tabs {
        .nav-item {
          max-width: 35%;
          &:nth-child(3) {
            max-width: 50%;
          }
        }
      }
    }
  }
  .provider-detail .certif-approved .certif-icon {
    width: 40px;
    min-width: 30px;
    height: 32px;
  }
  .tick-head {
    margin-bottom: 0.938rem;
  }
  .content-main {
    padding: 4.75rem 1.25rem 0;
  }
  .footer.mt-80 {
    margin-top: 2.5rem;
    padding: 1.125rem 0.938rem;
  }
  .dashboard-main {
    padding: 0;
    .wave-bgs .dash-box {
      padding: 1.125rem 0.938rem;
    }
  }
  // super admin view provider top navigation tabs
  #vertical-tabs.nav-tabs .nav-item {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .edit-profile-pic {
    .avatar-img {
      width: 100px;
      height: 100px;
      + .ml-29 {
        margin-left: 12px;
      }
    }
  }
  .viewscreen {
    .row {
      // direct child
      > div {
        margin-bottom: 12px;
      }
    }
  }
  .form-footer-full {
    .col-md-4 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      text-align: center;
      border-top: 0.1rem solid #e3e5e5;
      padding-top: 16px;
      margin-top: 16px;
    }
    .col-md-8 {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      .btn {
        padding: 0.7rem 1rem;
      }
    }
  }
  .authcontent-outer.authcontent-outer2 {
    padding: 0rem 0px 1rem 15px !important;
  }
  .form-footer-full {
    z-index: initial;
  }
}
@media (min-width: 991px) {
  .d-lg-flex {
    display: flex;
  }
}
//max991
@media #{$breakpoint-sm-only} {
  // .loggedin-user div {
  //   margin-right: 0;
  // }
  .tabname {
    font-size: 14px;
  }

  .header-outer {
    padding: 0 1.25rem;
    .navbar .navbar-nav {
      background-color: white;
      width: calc(100% + 2.5rem);
      position: absolute;
      margin-left: 0;
      left: -1.25rem;
      padding: 1rem 1.25rem;
      top: 3.875rem;
      z-index: 111;
      box-shadow: 0rem 0rem 0rem 0.313rem rgba(38, 38, 38, 0.05);
      .nav-link.active {
        .active-border {
          display: none !important;
        }
      }
    }
  }
  .navbar-collapse {
    flex-basis: auto;
  }
  .login-bg {
    min-height: 55vh;
  }
  .actionbar {
    .btn {
      display: block;
      margin-top: 12px;
      &.ms-5 {
        margin-left: 0 !important;
      }
    }
    button {
      width: 100%;
    }
  }
  #openNav {
    display: block;
  }
  .hamburgers {
    background: transparent;
    border: none;
    cursor: pointer;
    height: 25px;
    position: relative;
    top: 2px;
    padding: 0;
    min-height: inherit;
    min-width: inherit;
    width: 30px;
    z-index: 1;
    transform: scale(0.8);
    transition: 0.25s ease-in-out;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #3e4d3a;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 10px;
        width: 70%;
      }
      &:nth-child(3) {
        top: 20px;
      }
    }
  }
  .mainsidebar {
    width: 0;
  }
  .contentwrapper {
    margin-left: 0;
    transition: all 0.3s ease-in-out;
    padding: 0 15px 68px 15px;
  }
  .mainheader {
    margin-left: 0;
    padding: 20px 15px 0 15px;
  }
  footer {
    left: 0;
  }
  .nav-open {
    #overlay {
      background-color: rgba(0, 0, 0, 0.4);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      width: 100%;
      height: 100%;
    }
    .mainsidebar {
      width: $menu-width;
    }

    .hamburgers {
      span:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
        width: 100%;
        top: 8px;
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
        top: 8px;
      }
    }
  }
  .careauth-sidebar-outer .careauth-sidebar-inner {
    padding: 5.625rem 5px 0.063rem;
  }
  .pageNotFound {
    min-height: 100vh;
    .cta404 {
      margin-top: 0;
      h1 {
        font-size: 3rem;
      }
    }
  }
}
//min768 and max991
@media #{$breakpoint-md} {
  // html {
  //   font-size: 8px;
  // }
  .authcontent-outer {
    padding: 4rem 0rem 4rem 0rem;
  }
  .plr-70 {
    padding: 0;
  }
}
//min992 and max1024
@media #{$breakpoint-md-lg} {
  .header-outer .navbar .navbar-nav {
    margin-left: 1rem;
    .nav-item {
      margin-right: 1.563rem;
    }
  }
}
//max1199
@media #{$breakpoint-lg-max} {
  .pdr-lg-0 {
    padding-right: 0;
  }
  .profile-main {
    padding: 4.75rem 2.5rem 0rem;
  }
  .content-main {
    padding: 4.75rem 1.25rem 0rem;
  }
  .authcontent-outer {
    padding: 2.5rem 0rem 1.875rem 0rem;
    .no-wrap {
      white-space: pre-line;
    }
    &.authcontent-outer2 {
      padding: 6rem 0 1rem 0;
      .authcontent-inner {
        max-width: 100%;
      }
    }
  }
  .plr-70 {
    padding: 0 1rem;
  }
  #vertical-tabs.nav-tabs .nav-item {
    font-size: 14px;
  }
}
//max1440
@media (max-width: 1410px) {
}
//min1921 max2500
@media #{$breakpoint-xxl} {
  html {
    font-size: 14px;
  }
}
//2501 and 3100
@media #{$breakpoint-xxxl} {
  html {
    font-size: 20px;
  }

  .date-picker-time-select .react-datepicker {
    min-width: 460px;
  }
}
@media #{$breakpoint-xxxxl} {
  html {
    font-size: 30px;
  }

  .date-picker-time-select .react-datepicker {
    min-width: 500px;
  }
}

@media (max-width: 1300px) {
  .table-scroll {
    overflow-x: auto;
  }
}
