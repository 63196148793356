.minvh-h {
  min-height: 100vh;
}

button,
a,
span,
li,
i,
input,
select,
textarea {
  // transition: all 0.5s;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}
.disabled {
  background-color: $aliceblue;
  color: $rockblue;
  pointer-events: none;
}
.ml-auto {
  margin-left: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.toRight {
  text-align: right;
}
.clr-bluezodiac {
  color: $bluezodiac;
}
.clr-rockblue {
  color: $rockblue;
}
.clr-malibu {
  color: $malibu;
}
.clr-demin {
  color: $demin !important;
}
.clr-brightgray {
  color: $brightgray;
}
.clr-green {
  color: $green;
}
.clr-orange {
  color: $orange;
}
.clr-red {
  color: $red;
}
.clr-black {
  color: $black;
}
.fs-12 {
  font-size: 0.75rem;
}
.fs-14 {
  font-size: 14px;
}
.fs-24 {
  font-size: 1.5rem !important;
}
.fs-25 {
  font-size: 1.5rem;
}
.fs-44 {
  font-size: 2.75rem;
}
.fs-52 {
  font-size: 3.25rem;
}
.rotate-180 {
  transform: rotate(180deg);
}
.anchor-link {
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
}
.error {
  color: $speechred;
  font-size: 0.75rem;
}
hr {
  border-top: 0.063rem solid $patternblue;
  margin: 2rem auto;
  opacity: 1;
  background-color: transparent;
}
.my-line {
  margin: 2rem auto;
  width: 98%;
  height: 0.063rem;
  background-color: $aliceblue;
}
.bordered-div {
  border: 0.063rem solid $aliceblue;
  border-radius: 0.25rem;
  padding: 1.25rem;
}
.carecard {
  background: $white;
  box-shadow: 0rem 0rem 1.5rem rgba(0, 19, 127, 0.05);
  border-radius: 1rem;
  padding: 2.5rem;
  &.inner-card {
    padding: 1.875rem;
  }
}
.double-weight-text {
  span {
    font-size: $tertiary-fs;
    color: $brightgray;
    &:nth-child(1) {
      font-weight: 700;
    }
  }
  &.font-primary {
    span {
      font-size: $primary-fs;
    }
  }
}
.no-caret.dropdown-toggle::after {
  display: none;
}
.modal-backdrop {
  background: rgba(38, 38, 38, 0.8);
  &.show {
    opacity: 1;
  }
}
.criteria-passed.p4 span {
  color: #398333;
}
@mixin custom-ul {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}
#openNav {
  display: none;
}
.scroller {
  overflow: auto;
  height: calc(100vh - 175px);
}

.alert-scroller {
  .alert-dismissible {
    padding-right: 1.3rem;
  }
  .content {
    overflow: auto;
    max-height: 200px;
    ul {
      li {
        padding-right: 30px;
      }
    }
  }
}
//widths
.mw-710 {
  max-width: 44.375rem;
  width: 100%;
}
.mw-600 {
  max-width: 37.5rem;
  width: 100%;
}
.mw-590 {
  max-width: 36.875rem;
  width: 100%;
}
.mw-545 {
  max-width: 34.063rem;
  width: 100%;
}
.mw-480 {
  max-width: 30rem;
  width: 100%;
}

.mr-24 {
  margin-right: 1.5rem;
}
.mr-3 {
  margin-right: 0.19rem;
}
.ml-1 {
  margin-left: 0.063rem;
}

.ml--4 {
  margin-left: 0.25rem;
}

.ml-29 {
  margin-left: 1.813rem;
}

.mr-10 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 0.313rem;
}

.ml-10 {
  margin-left: 1rem;
}

// Margins
.mt--16 {
  margin-top: -1rem !important;
}
.mt--3 {
  margin-top: 0.188rem !important;
}
.mt--4 {
  margin-top: 0.25rem !important;
}
.mt_1 {
  margin-top: 0.063rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-18 {
  margin-top: 1.125rem;
}
.mt-20 {
  margin-top: 1.25rem;
}
.mt-24 {
  margin-top: 1.5rem;
}
.mt-30 {
  margin-top: 1.875rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mt-36 {
  margin-top: 2.25rem;
}
.mt-40 {
  margin-top: 2.5rem;
}
.mt-42 {
  margin-top: 2.625rem;
}
.mt-48 {
  margin-top: 3rem;
}
.mt-50 {
  margin-top: 3.125rem;
}
.mt-60 {
  margin-top: 3.75rem;
}
.mt-64 {
  margin-top: 4rem;
}
.mt-70 {
  margin-top: 4.375rem;
}
.mt-80 {
  margin-top: 5rem;
}
.mt-90 {
  margin-top: 5.625rem;
}
.mt-100 {
  margin-top: 6.25rem;
}

.mb-8 {
  margin-bottom: 0.313rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.mb-18 {
  margin-bottom: 1.125rem;
}
.mb-20 {
  margin-bottom: 1.25rem;
}
.mb-24 {
  margin-bottom: 1.5rem;
}
.mb-30 {
  margin-bottom: 1.875rem;
}
.mb-32 {
  margin-bottom: 2rem;
}
.mb-40 {
  margin-bottom: 2.5rem;
}
.mb-50 {
  margin-bottom: 3.125rem;
}
.mb-60 {
  margin-bottom: 3.75rem;
}
.mb-70 {
  margin-bottom: 4.375rem;
}
.mb-80 {
  margin-bottom: 5rem;
}
.mb-90 {
  margin-bottom: 5.625rem;
}
.mb-100 {
  margin-bottom: 6.25rem;
}

.mr-19 {
  margin-right: 1.188rem;
}
.mr-20 {
  margin-right: 1.25rem;
}

// Paddings
.p-40 {
  padding: 2.5rem;
}
.pt-16 {
  padding-top: 1rem;
}
.pt-18 {
  padding-top: 1.125rem;
}
.pt-20 {
  padding-top: 1.25rem;
}
.pt-24 {
  padding-top: 1.5rem;
}
.pt-30 {
  padding-top: 1.875rem;
}
.pt-32 {
  padding-top: 2rem;
}
.pt-40 {
  padding-top: 2.5rem;
}
.pt-50 {
  padding-top: 3.125rem;
}
.pt-60 {
  padding-top: 3.75rem;
}
.pt-70 {
  padding-top: 4.375rem;
}
.pt-80 {
  padding-top: 5rem;
}
.plr-70 {
  padding: 0rem 4.375rem;
}
.pt-90 {
  padding-top: 5.625rem;
}
.pt-100 {
  padding-top: 6.25rem;
}

.pb-16 {
  padding-bottom: 1rem;
}
.pb-18 {
  padding-bottom: 1.125rem;
}
.pb-20 {
  padding-bottom: 1.25rem;
}
.pb-24 {
  padding-bottom: 1.5rem;
}
.pb-30 {
  padding-bottom: 1.875rem;
}
.pb-32 {
  padding-bottom: 2rem;
}
.pb-40 {
  padding-bottom: 2.5rem;
}
.pb-50 {
  padding-bottom: 3.125rem;
}
.pb-60 {
  padding-bottom: 3.75rem;
}
.pb-70 {
  padding-bottom: 4.375rem;
}
.pb-75 {
  padding-bottom: 4.688rem;
}
.pb-80 {
  padding-bottom: 5rem;
}
.pb-90 {
  padding-bottom: 5.625rem;
}
.pb-100 {
  padding-bottom: 6.25rem;
}
.px-32 {
  padding-right: 32px;
  padding-left: 32px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #d4eefa;
  border: 0px none #ffffff;
  border-radius: 10px;

  &:hover {
    background: #d4eefa;
  }

  &:active {
    background: #d4eefa;
  }
}

::-webkit-scrollbar-track {
  background: #f1f7fa;
  border: 0px none transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

// Note: Below code is for Firefox
* {
  // Set the thumb and track color of the scrollbar
  scrollbar-color: #d4eefa #f1f7fa;
}

.backarrow {
  color: $rockblue;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  img {
    margin-right: 12px;
  }
  &:hover {
    color: $rockblue;
    text-decoration: none;
  }
}
.modal-table-scroll {
  max-height: 400px;
  overflow: auto;
}
.pagination {
  .page-item {
    margin-right: 0.8rem;
    &:last-child {
      margin-right: 0;
      .page-link {
        .icon-chevronleft {
          display: inline-block;
          transform: rotate(180deg);
        }
      }
    }
    &:first-child {
      .page-link {
        .icon-chevronleft {
          left: -0.2rem;
        }
      }
    }
    .page-link {
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $rockblue;
      width: 32px;
      height: 32px;
      font-size: 12px;
      line-height: 18px;
      color: $black;
      font-weight: 400;
      padding: 0;
      cursor: pointer;
      span {
        font-size: 24px;
      }
      &:hover {
        text-decoration: none;
        background-color: $patternblue;
        color: $demin;
        font-weight: 700;
        border: 1px solid $patternblue;
        &.icon-chevronleft::before {
          color: $white;
        }
      }
      &:focus {
        box-shadow: none;
        background-color: transparent;
        &.icon-chevronleft::before {
          color: #000;
        }
        &:hover {
          background-color: $patternblue;
          color: $demin;
          &.icon-chevronleft::before {
            color: $white;
          }
        }
      }
      &.dots-link {
        border: 0;
        background-color: transparent;
        cursor: default;
        font-size: 2rem;
        line-height: 0.6;
        letter-spacing: -0.05rem;
        font-weight: 400;
        display: inline;
        vertical-align: unset;
        &.active,
        &:hover {
          background-color: $white;
          color: $demin;
        }
      }
      &.next-icon,
      &.prev-icon {
        &::before {
          display: inline-block;
          font-size: 2rem;
          position: relative;
          top: 0.1rem;
        }
      }
      &.next-icon {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
    &.selected {
      .page-link {
        background-color: $patternblue;
        border: 1px solid $patternblue;
        color: $demin;
        font-weight: 700;
        .icon-chevronleft::before {
          color: $white;
          background-color: $demin;
        }
      }
    }
    &.disabled {
      .page-link {
        cursor: default;
        span::before {
          color: $neurtral300;
        }
      }
    }
    .next-icon {
      span::before {
        color: $demin;
      }
    }
    .prev-icon {
      span::before {
        color: $demin;
      }
    }
  }
}

.custom-select__control {
  border-color: $rockblue !important;
}

.custom-select__control--is-focused {
  box-shadow: none !important;
}

.custom-select__placeholder {
  color: $brightgray !important;
}

.custom-select__indicator-separator {
  display: none;
}
.custom-select__menu {
  z-index: 10 !important;
}

.custom-select__value-container {
  padding: 0.33rem 0.625rem !important;
}
.searchfilter {
  width: 100%;
  background: white;
  position: absolute;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 0px 0px 4px 4px;
  padding: 16px 24px;
  z-index: 12;
  .customdate {
    padding-right: 40px !important;
  }
}
.clearfilter {
  background-color: transparent;
  border: 0;
  color: $demin;
  text-decoration: underline;
  margin-left: 12px;
}
.fulllink {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  /* left: 0; */
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
}
.customdate {
  background-color: transparent !important;
  position: relative;
}
.v-align-middle {
  vertical-align: middle;
}
.no-wrap {
  white-space: nowrap;
}
.scroll-table {
  max-width: 100%;
  overflow-x: auto;
  td {
    min-width: 125px;
  }
}
.v-space-table {
  tr {
    td {
      padding: 0.9rem 0 0.9rem 0;
    }
  }
}

.react-datepicker__navigation {
  top: 11px !important;
  .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon--next::before {
    border-color: $white;
  }
  &:hover {
    .react-datepicker__navigation-icon--previous::before,
    .react-datepicker__navigation-icon--next::before {
      border-color: $white;
      opacity: 1;
    }
  }
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: $white !important;
  top: 0 !important;
}

.react-datepicker {
  border: 2px solid #f1f7fa !important;
  border-radius: 4px !important;
  padding: 16px;
  filter: drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.1));
}

.date-picker-time-select .react-datepicker {
  width: 420px;
}

.react-datepicker-popper {
  z-index: 12 !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 6px !important;
}
.react-datepicker__header {
  background-color: $white !important;
  color: $white;
  border-bottom: 1px solid #d4eefa !important;
  .react-datepicker__current-month {
    color: $demin;
  }
  .react-datepicker__header__dropdown {
    .react-datepicker__month-select,
    .react-datepicker__year-select {
      color: $white;
      background-color: $demin;
      padding: 2px 15px 2px 15px;
      background-image: none;
    }
    .react-datepicker__month-select {
      padding: 2px 5px 2px 5px;
      text-align: center;
    }
  }
  .react-datepicker__day-names {
    .react-datepicker__day-name {
      color: #b5bec6;
      text-transform: uppercase;
    }
  }
}

.react-datepicker__input-time-container {
  .react-datepicker-time__input-container {
    width: 70%;
    margin-left: 10px;
  }

  .react-datepicker-time__caption {
    font-size: 16px;
    font-weight: 600;
    color: $demin;
  }
  .react-datepicker-time__input {
    border: 0.7px solid $patternblue !important;
    border-radius: 4px !important;
    padding: 0px !important;
    height: 40px;
    font-size: 16px;
    width: 100% !important;
    margin-left: 0px !important;
    .react-datepicker-time__input {
      padding: 0px 8px !important;
    }
  }
}

.react-datepicker__day {
  color: #262b39;
  font-size: 16px;
  font-weight: 500px;
}

.react-datepicker-time__header {
  color: $demin !important;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: -20px !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: $demin !important;
  color: $white !important;
  border-radius: 100px !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 100px !important;
}
.react-datepicker__navigation .react-datepicker__navigation-icon--previous::before {
  border-color: #216ba5;
}
.react-datepicker__navigation:hover .react-datepicker__navigation-icon--previous::before {
  border-color: #216ba5;
}
.react-datepicker__navigation .react-datepicker__navigation-icon--next::before {
  border-color: #216ba5;
}
.react-datepicker__navigation:hover .react-datepicker__navigation-icon--next::before {
  border-color: #216ba5;
}
.clearicon {
  background-color: transparent;
  padding: 0;
  border: 0;
}

.timesheet,
.viewtimesheet,
.viewpaymentsheet {
  margin-top: 24px;
  .table > thead > tr > th {
    padding: 5px 5px 5px 5px;
    vertical-align: middle;
    background-color: #d4eefa;
  }
  .table > :not(caption) > * > * {
    vertical-align: inherit;
    padding: 16px 5px 8px 5px;
  }
  table {
    thead {
      background: #d4eefa;
    }
  }
  .clearicon {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
  }
  .error {
    position: absolute;
  }
  .form-group {
    .holidaycheck {
      margin-top: 5px;
      &.form-checkbox label:before {
        width: 15px;
        height: 15px;
      }
      &.form-checkbox label:after {
        top: 2px;
        left: 5px;
      }
      &.form-checkbox label {
        padding-left: 20px;
        font-size: 12px;
        line-height: 1.5;
        display: inline;
      }
    }
    .form-checkbox label {
      display: inline;
    }
  }
  .form-group {
    .form-controls {
      .react-datepicker-wrapper {
        .customdate {
          width: 85px !important;
        }
      }
    }
  }
  .pagers-col {
    .form-checkbox {
      // width: 70px;
      // width: 55px;
      border: 0.063rem solid #94a3b8;
      border-radius: 0.25rem;
      text-align: center;
      padding: 7px 0 6px 0;
      label {
        padding-left: 1.4rem;
      }
    }
  }
  .service-col {
    width: 200px;
  }
  input[type='number'] {
    text-align: center;
  }
  .addmore {
    width: 24px;
    height: 24px;
    padding: 0;
    border: 1px solid #d4eefa;
    border-radius: 4px;
    span {
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
    }
    &:disabled {
      background-color: #f1f1f1;
    }
  }
  .daydate-col {
    min-width: 125px;
  }
}
.dailytimelog {
  .addmore {
    &.disabled,
    span {
      color: $rockblue;
    }
  }
}

.dailyTimeSheet.clearicon {
  position: absolute;
  top: 12px;
  right: 10px;
}

.viewtimesheet {
  margin-top: 0;
  .table > :not(caption) > * > * {
    padding: 32px 5px 8px 5px;
  }
  table {
    td {
      border-bottom: 1px solid #d4eefa;
    }
  }
  .pagers-col {
    .form-checkbox {
      border: 0;
      text-align: left;
      padding: 0;
    }
  }
  .regularshift-col,
  .service-col {
    min-width: 125px;
  }
}

.viewpaymentsheet {
  table {
    thead {
      background: none;
      border: 0px solid white;
    }
    td {
      border-bottom: 1px solid #d4eefa;
    }
  }
}

.viewscreen {
  .paymentscard {
    padding: 1.25rem 3.125rem 1.25rem 1.5rem;
    border: 1px solid rgba(148, 163, 184, 0.3);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    min-height: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: $aliceblue2; // Background color on hover
      transition: all 0.3s ease-in-out;
      p {
        color: $demin;
      }
      h4 {
        color: $demin;
      }
    }
  }
}

.errorfallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding: 16px;
}
