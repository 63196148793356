@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?a6mlds');
  src: url('../fonts/icomoon.eot?a6mlds#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?a6mlds') format('truetype'),
    url('../fonts/icomoon.woff?a6mlds') format('woff'),
    url('../fonts/icomoon.svg?a6mlds#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}

@import 'partials/variables';
@import 'partials/fonts';
@import 'partials/guide';
@import 'partials/style';
@import 'partials/others';
@import 'partials/responsive';
